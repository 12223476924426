/* Sion-ICT corporation site style sheet with Bootstrap */
/* (c) 2016 Sion-ICT All rights reserved                */

/* general reset */
* {
    padding: 0;
    margin: 0;
}

/* site styles */
a:link              { color:#333333; text-decoration:none; }
a:visited           { color:#333333; text-decoration:none; }
a:hover             { color:#999999; text-decoration:none; }
a:active            { color:#333333; text-decoration:none; }

html, body {
    background-color: #333333;
    font-family: tahoma, Arial, sans-serif;
    font-size: 20px;
}

#sitebody {
    background-image: url(../images/background.jpg);
    background-size: 100%;
    background-repeat: repeat;
}

/* elements */
.headContainer {
   width:100%;
}


/* .stpanel
{
    background:rgba(255,255,180,0.4);
    margin-top: 10px;
    margin-bottom: 10px;
    padding:5px 5px 5px 5px;
} */

@media screen and (max-width: 767px) {
    .stpanel {
        margin-left: -15px;
        margin-right: -15px;
        margin-top: 0 !important;
    }
    /* Bootstrap navbar color override */
    .navbar-default {
        background-color: rgba(255, 255, 180, 0);
        border-color: rgba(255, 255, 180, 0);
    }

    .navbar-nav {
        background-color: rgba(255, 255, 180, 0.5);
    }
}

.logo {
    float:left;
	margin: 5px 0px 5px -5px;
}

.logoSmall {
    float:left;
	height:25px;
	border:0;
    margin:0 5px 2px -12px;
}

.spinner {
    float:left;
	height:50px;
	border:0;
    margin:100px 100px 100px 150px;  
}

.menu {
    width: 100%;
    border-bottom:2px solid black;
    margin-bottom:20px;
}

.menuitem {
    float:right;
	font-size:20px;
	text-align:center;
	padding:20px 0 0 0;
    margin:0 50px 5px 0;
}

.mainImageContainer {
    text-align:center;
}

.mainImage {
    width: 70%;
    margin: 30px 0 50px 0;
    border-color: #000000;
}

/* .certification {
    margin: 15px 10px 15px 10px;
    text-align: center;
    height: 106px;
    width: 155px;
    background-color: white;
}

.certificationImage {
    height: 70px;
    width: 155px;
} */

.footerSide {
	text-align:left;
    margin-top:5px;
    font-size: 16px;
}

.footerCenter {
    margin-top:5px;
    font-size: 16px;
}
 
/* texts */
.header {
	font-size:20px;
}

/* .paragraph
{
} */

.contentContainer {
    margin-bottom: 50px;
    min-height: 600px;
}

.addy {
	text-decoration:underline;
}

.footerLink, .footerLink a {
	text-decoration:underline;
	color:#cccccc;
}

.footerLink a:hover {
	color:#ffffff;
}

.footerText {
	color: #cccccc;
}


.socialImage {
	width:15px;
	height:15px;
	border:0;
    margin:0 5px 2px 0;
}

.standardLink {
	text-decoration:underline;
}

.smallText {
    font-size: 12px;
}

.greytext {
	color:#aaaaaa
}

.jsWarning {
	background-color: #cccccc;
}